<template>
    <div
        id="send-to-space"
        class="h-full flex flex-col"
    >
        <BoxIntro
            :box="props.box"
            :address="props.box.tracking?.destinationAddress"
            :is-editable="true"
            :edit-tab-index="4"
            @editDetails="$emit('editDetails')"
            @changeImage="$emit('changeImage')"
        />
        <div class="px-[21px] mb-16">
            <p class="text-navy text-[14px] mb-4">
                {{ props.box.description }}
            </p>
            <div class="flex gap-2 items-center mb-4">
                <div>
                    <i
                        class="icon icon-van scale-x-[-1] text-navy text-[15px]"
                    />
                </div>
                <div class="w-11/12 bg-flushing-pink h-[6px] rounded-full" />
            </div>
            <div class="flex flex-wrap gap-2">
                <p
                    v-for="(tag, index) in props.box.tags"
                    :key="index"
                    class="bg-transparent text-[12px] text-navy border border-dusk-stalk rounded-full break-all py-[5px] px-[11px]"
                >
                    {{ tag }}
                </p>
            </div>
        </div>
        <div class="flex flex-col items-center mt-auto p-[21px]">
            <button
                class="btn-secondary h-[65px] mb-2"
                :tabindex="1"
                data-message="Drop Off Button"
                @click="onDropOffClicked"
            >
                Drop it Off
            </button>

            <button
                class="btn-primary h-[65px] mb-2"
                :tabindex="2"
                data-message="Schedule Pick Up Button"
                @click="onPickUpClicked"
            >
                Schedule Pick Up
            </button>

            <div class="text-center">
                <span class="text-[12px] text-navy">
                    No longer need your box?

                    <button
                        class="text-flushing-pink font-medium hover:text-bloody-nose"
                        :tabindex="3"
                        data-message="Cancel Subscription Button"
                        @click="isCancelSubscriptionDrawerShown = true"
                    >
                        Cancel subscription
                    </button>
                </span>
            </div>
        </div>
        <Drawer
            :is-shown="isCancelSubscriptionDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :back-tab-index="3"
            :close-tab-index="4"
            @close="SHOW_DRAWER(null)"
            @back="isCancelSubscriptionDrawerShown = false"
        >
            <CancelSubscription
                :box="props.box"
                @close="isCancelSubscriptionDrawerShown = false"
            />
        </Drawer>
        <Drawer
            :is-shown="isCancelSubscriptionDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            @close="SHOW_DRAWER(null)"
            @back="isCancelSubscriptionDrawerShown = false"
        >
            <CancelSubscription
                :box="props.box"
                @close="SHOW_DRAWER(null)"
            />
        </Drawer>
        <Drawer
            :is-shown="isScanSelectBoxIdentifierDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            @close="SHOW_DRAWER(null)"
            @back="isScanSelectBoxIdentifierDrawerShown = false"
        >
            <ScanSelectBoxIdentifier
                :box="props.box"
                @view="isScanSelectBoxIdentifierDrawerShown = false"
                @success="isScanSelectBoxIdentifierDrawerShown = false"
            />
        </Drawer>
        <Drawer
            :is-shown="isTermsAndConditionDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :close-tab-index="3"
            :back-tab-index="2"
            @close="SHOW_DRAWER(null)"
            @back="isTermsAndConditionDrawerShown = false"
        >
            <TermsAndCondition @agree="isUpsProtectionPlanDrawerShown = true" />
        </Drawer>

        <Drawer
            :is-shown="isUpsProtectionPlanDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :close-tab-index="4"
            :back-tab-index="3"
            @close="SHOW_DRAWER(null)"
            @back="isUpsProtectionPlanDrawerShown = false"
        >
            <UpsProtectionPlan @view="onUpsAgree" />
        </Drawer>
        <Drawer
            :is-shown="isPickUpLocationDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :close-tab-index="4"
            :back-tab-index="3"
            @close="SHOW_DRAWER(null)"
            @back="isPickUpLocationDrawerShown = false"
        >
            <PickupLocation :box="props.box" />
        </Drawer>
        <Drawer
            :is-shown="isDropOffReviewDrawerShown"
            bg-class="bg-white-noise bg-white"
            :is-back-shown="true"
            :is-close-shown="true"
            :close-tab-index="6"
            :back-tab-index="5"
            @close="SHOW_DRAWER(null)"
            @back="isDropOffReviewDrawerShown = false"
        >
            <DropOffReview :box="props.box" />
        </Drawer>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { mapMutations } from 'root-js/store/lib.js';

    import BoxIntro from 'root-js/components/your-space/BoxIntro.vue';
    import Drawer from 'root-js/components/common/Drawer.vue';
    import CancelSubscription from 'root-js/components/your-space/CancelSubscription.vue';
    import ScanSelectBoxIdentifier from 'root-js/components/your-space/ScanSelectBoxIdentifier.vue';
    import TermsAndCondition from 'root-js/components/your-space/TermsAndCondition.vue';
    import UpsProtectionPlan from 'root-js/components/your-space/UpsProtectionPlan.vue';
    import PickupLocation from 'root-js/components/your-space/PickupLocation.vue';
    import DropOffReview from 'root-js/components/your-space/DropOffReview.vue';

    const props = defineProps({ box: Object });

    const { SHOW_DRAWER } = mapMutations('drawers');

    const isCancelSubscriptionDrawerShown = ref(false);
    const isScanSelectBoxIdentifierDrawerShown = ref(false);
    const isTermsAndConditionDrawerShown = ref(false);
    const isUpsProtectionPlanDrawerShown = ref(false);
    const isDropOffReviewDrawerShown = ref(false);
    const isPickUpLocationDrawerShown = ref(false);
    const selectedReturnType = ref(null);

    function onDropOffClicked() {
        isTermsAndConditionDrawerShown.value = true;
        selectedReturnType.value = 'DROP_OFF';
    }

    function onPickUpClicked() {
        isTermsAndConditionDrawerShown.value = true;
        selectedReturnType.value = 'PICK_UP';
    }

    function onUpsAgree() {
        if (selectedReturnType.value === 'DROP_OFF') {
            isDropOffReviewDrawerShown.value = true;
        } else {
            isPickUpLocationDrawerShown.value = true;
        }
    }
</script>
