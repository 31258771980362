<template>
    <div class="mb-4 px-6">
        <div
            v-if="!activePromotion"
            class="w-full"
        >
            <label
                for="promo-code"
                class="block text-navy text-[14px] mb-1 ml-3"
            >
                Have a promotion code? Enter it here
            </label>
            <div class="w-full">
                <div
                    v-if="!isLoading"
                    class="flex justify-between items-center"
                >
                    <input
                        id="promo-code"
                        v-model="promoCode"
                        type="text"
                        name="promo-code"
                        class="promo-code-input bg-white py-[16px] px-[26px] rounded-[20px] text-[14px] text-navy transition duration-75 ease-linear placeholder:text-bruised-ego shadow-input outline-none focus:shadow-md disabled:bg-slate-50 disabled:border-slate-300 disabled:text-slate-300 disabled:placeholder-slate-300 disabled:shadow-none w-full"
                        :class="{ 'animate-shake': showError }"
                        placeholder="Enter your promotion code"
                        @keyup.enter.prevent.stop="applyPromoCode"
                        @keydown.enter.prevent.stop
                    >
                    <button
                        class="w-1/3 ml-2 btn-primary rounded-[30px] py-[12px] px-[20px]"
                        @click="applyPromoCode"
                    >
                        Apply
                    </button>
                </div>
                <img
                    v-else
                    class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                    :src="require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                    "
                    alt="Icon Spinner"
                >
            </div>
            <p
                v-if="showError"
                class="ml-3 mt-2 text-sm text-red-600 animate-fade-in"
            >
                {{ errorMessage }}
            </p>
        </div>
        <div
            v-else
            class="bg-[#75EFC8] p-5 rounded-[20px] mb-4 animate-bounce-in"
        >
            <div class="flex justify-between items-center">
                <p class="text-[#1F496E] font-bold">
                    {{ activePromotion.code }}: <span class="text-[#1F496E] font-normal">{{ activePromotion.amount_off ? `$${readableDecimalNumber(activePromotion.amount_off)} off` : `${activePromotion.percent_off}% off` }}</span>
                </p>
                <button
                    class="text-emerald-600 hover:text-emerald-800"
                    @click="removePromotion"
                >
                    <span class="sr-only">Remove promotion</span>
                    <i
                        class="icon icon-cross rounded-full bg-flushing-pink text-white p-1 cursor-pointer"
                    />
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, reactive, watch } from 'vue';
    import Form from 'root-js/utils/API/Form';
    import { mapGetters } from 'root-js/store/lib.js';
    import { readableDecimalNumber } from 'root-js/helpers/number.helper';


    const emit = defineEmits(['coupon-applied', 'coupon-removed']);

    const { cartBoxes } = mapGetters('store');

    const promoCode = ref('');
    const activePromotion = ref(null);
    const showError = ref(false);
    const errorMessage = ref('');
    const isLoading = ref(false);

    const couponForm = reactive(new Form('verify-promotion-code',
                                         {
                                             promotion_code: { value: '', rule: 'required' },
                                             items: {
                                                 value: cartBoxes.value.map(cartBox => {
                                                     return {
                                                         id: cartBox.id,
                                                         quantity: cartBox.quantity
                                                     };
                                                 })
                                             }
                                         },
                                         null));

    watch(() => cartBoxes.value,
          () => {
              couponForm.items = cartBoxes.value.map(cartBox => {
                  return {
                      id: cartBox.id,
                      quantity: cartBox.quantity
                  };
              });

          },
          { deep: true });


    const applyPromoCode = async () => {
        showError.value = false;
        couponForm.promotion_code = promoCode.value;
        isLoading.value = true;

        try {
            const response = await couponForm.submit(true);
            handleSuccessfulCoupon(response.data);
        } catch (error) {
            handleCouponError(error.response.data.error);
        }

        isLoading.value = false;
    };

    const handleSuccessfulCoupon = response => {
        activePromotion.value = {
            id: response.id,
            code: response.code,
            amount_off: response.amount_off,
            percent_off: response.percent_off
        };
        emit('coupon-applied', activePromotion.value);
    };

    const handleCouponError = error => {
        showError.value = true;
        errorMessage.value = error.title === 'Not Found'
            ? 'Invalid promotion code'
            : error.title ? error.title : 'Invalid promotion code';
    };

    const removePromotion = () => {
        activePromotion.value = null;
        promoCode.value = '';
        emit('coupon-removed');
    };
</script>

<style scoped>
@keyframes shake {
0%, 100% { transform: translateX(0); }
10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
20%, 40%, 60%, 80% { transform: translateX(5px); }
}

@keyframes bounceIn {
0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
40% { transform: translateY(-20px); }
60% { transform: translateY(-10px); }
}

@keyframes fadeIn {
from { opacity: 0; }
to { opacity: 1; }
}

.animate-shake {
animation: shake 0.5s ease-in-out;
}

.animate-bounce-in {
animation: bounceIn 0.5s ease-in-out;
}

.animate-fade-in {
animation: fadeIn 0.3s ease-in-out;
}
</style>
