<template>
    <div class="text-navy">
        <router-link
            :to="{ name: 'Settings' }"
            class="flex items-center mr-4 hover:opacity-90 lg:pointer-events-none"
        >
            <i class="icon icon-arrow-left text-navy mr-4 lg:hidden" />
            <p class="text-[14px] text-navy">
                Settings / Deactivate Account
            </p>
        </router-link>

        <div class="mb-4 relative flex">
            <p
                class="text-[28px] w-[250px] tracking-[-0.7px] leading-[1.29] mt-8 mr-10"
            >
                Deactivate your account
            </p>
            <img
                class=""
                :src="require('/static/assets/images/misc/clouds-grey.svg')"
                alt="Clouds Grey"
            >
        </div>

        <p class="text-[14px] mb-8">
            Before deactivating your account, <br>
            you must cancel your subscriptions
        </p>
        <div>
            <button
                class="btn-primary h-[65px] md:w-[360px]"
                @click="onClickDeactivate"
            >
                Deactivate account
            </button>
        </div>

        <Dialog
            v-model:isShown="isDeactivateDialogShown"
            size-class="w-[300px] sm:w-[580px] xs:w-[460px]"
            spacing-class="py-[25px] px-[30px] sm:py-[52px] sm:px-[47px]"
        >
            <form
                class="w-full"
                autocomplete="off"
                novalidate
                @submit.prevent="submit"
                @keydown="
                    form.resetValidation('general');
                    form.resetValidation($event.target.name);
                "
                @keyup.enter.exact="submit"
            >
                <div class="flex gap-8 items-center">
                    <div class="flex flex-col mb-6 sm:mb-10">
                        <h2 class="text-navy text-[28px] mb-2">
                            Confirm deactivation
                        </h2>

                        <p
                            v-if="form.errors.has('general')"
                            class="text-[14px] text-flushing-pink font-medium w-[250px] sm:w-[300px] xs:w-[265px]"
                        >
                            {{ form.errors.get('general') }}
                        </p>
                        <p
                            v-else
                            class="text-navy text-[14px] w-[250px] max-w-full sm:w-[300px] xs:w-[265px]"
                        >
                            Are you sure you want to deactivate your account?
                            This cannot be undone
                        </p>
                    </div>
                    <img
                        class="absolute top-10 -right-10 w-[170px] sm:w-[249px] hidden xs:block"
                        :src="
                            require('/static/assets/images/misc/figures-alerts-general.png')
                        "
                        alt="Figures Alerts General"
                    >
                </div>

                <div class="flex gap-2">
                    <div
                        class="flex h-[45px] w-[105px] sm:w-[185px] sm:h-[65px] xs:w-[165px] xs:h-[55px]"
                    >
                        <button
                            v-if="!isLoading"
                            ref="deactivateButton"
                            type="submit"
                            class="btn-secondary text-[14px] xs:[text-16px]"
                            @click="onClickDeactivateAccount"
                        >
                            Deactivate
                        </button>
                        <img
                            v-else
                            class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                            :src="
                                require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                            "
                            alt="Icon Spinner"
                        >
                    </div>
                    <div>
                        <button
                            class="btn-primary text-[14px] w-[105px] h-[45px] sm:w-[185px] sm:h-[65px] xs:w-[165px] xs:h-[55px] xs:[text-16px]"
                            @click="onClickCancel"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </Dialog>
    </div>
</template>

<script setup>
    import { reactive, ref } from '@vue/reactivity';
    import { mapMutations } from 'root-js/store/lib.js';
    import { useMeta } from 'vue-meta';

    import router from 'root-js/routes.js';
    import Dialog from 'root-js/components/common/Dialog.vue';
    import Form from 'root-js/utils/API/Form';

    useMeta({ title: 'Space / Deactivate Account' });

    const emits = defineEmits([ 'success' ]);

    const { UNSET_USER } = mapMutations('user');

    const isDeactivateDialogShown = ref(false);
    const deactivateButton = ref(null);
    const isLoading = ref(false);

    const form = reactive(new Form('deactivate-account', {}, null, { success: onSuccess }));

    function onClickDeactivate() {
        isDeactivateDialogShown.value = true;
    }
    function onClickCancel() {
        isDeactivateDialogShown.value = false;
    }

    async function submit() {
        isLoading.value = true;

        try {
            await form.submit(true);
            UNSET_USER();
        } catch (error) {

        // Error intentionally ignored or handled elsewhere
        }
        isLoading.value = false;
    }

    function onSuccess() {
        isDeactivateDialogShown.value = false;
        router.push({ name: 'Welcome' });
    }
</script>
