import { BOX_STATE, BOX_SUB_STATE } from '../constants/space.const';

import dayjs from 'root-js/plugins/Dayjs';

export const getBoxState = (packingStatus, status) => {
    if (
        [
            'PICKED_UP_FROM_CUSTOMER',
            'DELIVERED_TO_WAREHOUSE',
            'READY_FOR_PICKUP',
            'READY_FOR_DROP_OFF'
        ].includes(status)
    ) {
        return {
            badgeText: 'Headed to Space',
            state: BOX_STATE.TRACK,
            subState: BOX_SUB_STATE.SENDING
        };
    } else if (
        [
            'PROCESSING',
            'REQUESTING',
            'REQUESTED',
            'SHIPPED_FROM_WAREHOUSE',
            'PICKED_UP_FROM_WAREHOUSE',
            'IN_TRANSIT'
        ].includes(status)
    ) {
        return {
            badgeText: 'Coming to you',
            state: BOX_STATE.TRACK,
            subState: BOX_SUB_STATE.ARRIVING_REQUEST
        };
    } else if (['READY_FOR_DROP_OFF', 'READY_FOR_PICKUP'].includes(status)) {
        return {};
    } else if (status === 'IN_WAREHOUSE') {
        return {
            badgeText: 'In Space',
            state: BOX_STATE.REQUEST
        };
    } else if (
        status === 'DELIVERED_TO_CUSTOMER'
        && packingStatus === 'unpacked'
    ) {
        return {
            badgeText: 'Arrived',
            state: BOX_STATE.START_PACKING
        };
    } else if (
        status === 'DELIVERED_TO_CUSTOMER',
        'READY_FOR_PICKUP' && packingStatus === 'packing'
    ) {
        return {
            badgeText: 'Arrived',
            state: BOX_STATE.CONTINUE_PACKING
        };
    } else if (
        status === 'DELIVERED_TO_CUSTOMER'
        && packingStatus === 'packed'
    ) {
        return {
            badgeText: 'Arrived',
            state: BOX_STATE.SEND_TO_SPACE
        };
    } else if (status === 'DELIVERY_FAILED') {
        return {};
    }

    return {
        badgeText: '',
        state: '',
        subState: ''
    };
};

export const formatBoxDetails = box => {
    const boxStates = getBoxState(box.packing_status, box.status);
    const boxImage = box.media.find(media => media.type === 'image');

    return {
        id: box.id,
        badgeText: boxStates.badgeText,
        imageId: boxImage?.id,
        image: boxImage?.media || { original: require('static/assets/images/misc/hero.png') },
        title: box.name || 'New Box',
        description: box.description || 'Imagine the possibilities…',
        state: boxStates.state,
        subState: boxStates.subState,
        dateCreated: dayjs(box.created_at).format('MMMM Do YYYY [at] hh:mm A'),
        dateUpdated: dayjs(box.updated_at).format('MMMM Do YYYY [at] hh:mm A'),
        address: '1234 Main St, Los Angeles, CA, 90123',
        tags: box.tags,
        packingStatus: box.packing_status,
        status: box.status,
        serialNumber: box.serial_number
    };
};

export function mmToInches(mm) {
    const inchesPerMillimeter = 0.0393701;
    const inches = mm * inchesPerMillimeter;

    // Convert to a string with two decimal places
    const fixedInches = inches.toFixed(2);

    // If the result ends with ".00", return the integer part only
    return parseFloat(fixedInches) % 1 === 0
        ? parseInt(fixedInches)
        : parseFloat(fixedInches);
}
