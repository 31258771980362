<template>
    <div
        id="track-arriving"
        class="h-full flex flex-col"
    >
        <BoxIntro
            :box="props.box"
            :address="props.box.tracking?.destinationAddress"
            :is-editable="false"
            @changeImage="$emit('changeImage')"
        />

        <div class="px-[21px]">
            <div class="flex gap-2 items-center mb-4">
                <div class="w-7/12 bg-the-abbys h-[6px] rounded-full" />
                <div>
                    <i class="icon icon-van scale-x-[-1] text-navy text-[15px]" />
                </div>

                <div class="w-3/12 bg-flushing-pink h-[6px] rounded-full" />
            </div>
        </div>
        <div class="flex justify-between items-center mt-auto p-[21px]">
            <p class="text-[12px] text-navy">
                Last updated: {{ props.box.dateUpdated }}
            </p>
            <i class="icon icon-info-circle-outline text-navy text-[21px]" />
        </div>
    </div>
</template>

<script setup>
    import BoxIntro from 'root-js/components/your-space/BoxIntro.vue';

    const props = defineProps({ box: Object });
</script>
