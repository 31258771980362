// Determine whether the build is running in production
const inProduction = process.env.NODE_ENV === 'production';

/*
 * Determine if user is using Internet Explorer and if so add the ie class to
 * the html
 */
if (navigator.userAgent.match(/(.*MSIE.*)|(.*Trident\/7\.0.*rv:11\.0.*)/iu)) {
    document.documentElement.className += ' ie';
}

/**
 * Load the core styles.
 */
import 'root-styles/app.css';

/**
 * Sentry is used to log and track all exceptions thrown in the production
 * environment.
 */
import * as Sentry from '@sentry/vue';

/**
 * Initiate the API instance to handle all calls to the REST API. Each
 * endpoint is defined in endpoints.json, and then imported into a
 * collection which is passed to the API class. Whilst you can call
 * a custom endpoint using the API class, it is recommended to define
 * all endpoints in the endpoints.json file.
 */
import API from 'root-js/services/APIService';
import App from './App.vue';
import Button from './components/common/Button';

/**
 * Load any specified global components for the application.
 */
import Field from './components/common/Field';
import Input from './components/common/Input';
import { Integrations } from '@sentry/tracing';
import Spinner from './components/common/Spinner';
import Unsupported from './Unsupported.vue';
import VueGtag from 'vue-gtag-next';

/**
 * No Scroll will register methods that can be used to disable the scrolling on
 * the browser window.
 */
import VueNoScroll from './plugins/VueNoScroll';

/**
 * Preload is used to preload image assets into the browser
 * cache.
 */
import VuePreloader from './plugins/VuePreloader';

/**
 * Apply any global navigation guards to the router.
 */
import bus from './services/BusService';

/**
 * Load the application's global configuration and provide it to Vue to be
 * globally accessible.
 */
import config from '~/config';

/**
 * Load Vue and create the app instance
 */
import { createApp } from 'vue';

/**
 * Vue-meta is used to handle all meta tags for each page within the app.
 *
 * For more info, see: https://github.com/declandewet/vue-meta
 */
/*
 * TODO: Known bug: if you change any meta information that is meant to be
 * unique, it will append, not replace. The old trick of using vmid is not
 * working in the latest release
 */
import { createMetaManager } from 'vue-meta';

/**
 * Load the Tailwind configuration in order to import the colour palette into
 * the app.
 */
import resolveConfig from 'tailwindcss/resolveConfig';

/**
 * We use the Vue Router plugin to handle all routes within the app. We start by
 * importing the router, and telling Vue to use the router. All routes are
 * defined in a separate file 'routes.js' located in the root of the js
 * directory.
 */
import router from './routes';

/**
 * Vuex is used to store any persistent data throughout the application such as
 * the user model and the authentication state.
 */
import store from './store';
import tailwindConfig from '~/tailwind.config.js';

/**
 * Generate a device identifier and store it.
 */
import { v4 as uuidv4 } from 'uuid';
import vClickOutside from 'click-outside-vue3';
import { PRELOAD_IMAGES } from './constants/preload.const';

const app = createApp(App);

app.provide('$config', config);

const generateReleaseVersion = () => {
    const packageVersion = process.env.npm_package_version || '1.0.0';
    const buildNumber
        = process.env.GITHUB_RUN_NUMBER || process.env.BUILD_NUMBER || 'dev';
    return `${packageVersion}.${buildNumber}`;
};

if (typeof config.services.sentry.dsn !== 'undefined') {
    Sentry.init({
        app,
        dsn: config.services.sentry.dsn,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: [ config.app.urls.api ]
            })
        ],
        release: generateReleaseVersion(), // Set the release version
        environment: process.env.NODE_ENV,
        ignoreErrors: [ 'Unsupported Browser' ]
    });
}

app.config.errorHandler = (err, instance, info) => {
    Sentry.captureException(err);

    // You can also add breadcrumbs for additional context
    Sentry.addBreadcrumb({
        category: 'error',
        message: err.message,
        level: 'error'
    });
};

// Initialize vue-gtag

app.use(VueGtag,
    {
        property: {
            id: config.services.googleAnalytics.id,
            params: { send_page_view: true }
        },
        appName: config.app.name,
        isEnabled: process.env.NODE_ENV === 'production',
        bootstrap: true
    },
    router);

/**
 * Modernizr is used for browser specific feature detection. So, if you are
 * building a site that uses modern features, for example, 'flexbox', you can
 * used Modernizr to test to see if the browser will support this. In order to
 * use Modernizr, you will need to generate your own build using the following
 * command:
 *
 * node_modules/modernizr/bin/modernizr -c src/js/modernizr-config.json
 *
 * Before running this command, ensure you have set all of the feature
 * requirements in the modernizr-config.json file.
 *
 * For more info, see: https://github.com/Modernizr/Modernizr
 */
require('./modernizr.js');

/*
 * Before we go any further, let's just make sure that the browser can handle
 * the awesomeness of our application.
 */
// eslint-disable-next-line no-undef
if (Object.values(Modernizr).indexOf(false) !== -1) {
    createApp(Unsupported).mount('#app');

    throw new Error('Unsupported Browser');
}

app.provide('$styles', resolveConfig(tailwindConfig));

/**
 * Load the Segment analytics when in production.
 */
if (typeof window.analytics !== 'undefined') {
    app.config.globalProperties.$analytics = window.analytics;
}

window.api = API;

app.use(router);

app.use(createMetaManager());

app.use(VuePreloader, { transitionDuration: 300 });

app.use(VueNoScroll);

app.component('BrandField', Field);
app.component('BrandInput', Input);
app.component('BrandButton', Button);
app.component('BrandSpinner', Spinner);

app.use(store);

const deviceID = store.state.auth.device || uuidv4();

store.dispatch('auth/SET_DEVICE', deviceID);

router.beforeEach((to, from, next) => {

    // Update the robots text dependent on whether the route should allow bots
    if (to.meta.preventCrawl) {
        document.head.querySelector("meta[name='robots']").content = 'noindex';
    } else {
        document.head.querySelector("meta[name='robots']").content
            = 'index, follow';
    }

    /*
     * Re-initialise the preloader upon route change
     * TODO: This should really be within the install method of the preloader
     * plugin but I couldn't get it to work using useRouter
     */
    if (to.meta.preload) {

        // Set loaded to false
        bus.emit('_preload.unloaded');

        /*
         *  TODO: If page transitions are enabled then this needs to be
         *  implemented outside of the if statement
         * setTimeout(next, options.transitionDuration || 0);
         */
    }

    next();
});

router.afterEach(to => {
    const noScroll = app.config.globalProperties.$noScroll;
    const analytics = app.config.globalProperties.$analytics;

    // Ensure the body is unlocked when changing route
    noScroll.forceUnlock();

    // Log the analytics event in production
    if (typeof analytics !== 'undefined') {
        analytics.page(to.name);
    }
});

/**
 * Determine if the active device is a touchscreen machine. As a device cannot
 * 'become' touchscreen, it is safe to store this value for the duration of the
 * session.
 */
const isTouchScreen = Object.freeze('ontouchstart' in window || navigator.msMaxTouchPoints > 0);

app.provide('$isTouchScreen', isTouchScreen);

app.use(vClickOutside);

// Mount the application once the router is ready
router.isReady().then(() => {
    app.mount('#app');

    if (typeof config.services.sentry.dsn !== 'undefined') {
        const { user } = store.state.user;
        if (user && user.id) {
            Sentry.setUser({
                id: user.id,
                phone: user.phone.phone,
                email: user.email
            });
        }
    }
});

if (typeof config.services.sentry.dsn !== 'undefined') {
    store.watch(state => state.user.user,
        newUser => {
            if (newUser && newUser.id) {
                Sentry.setUser({
                    id: newUser.id,
                    phone: newUser.phone.phone,
                    email: newUser.email
                });
            }
        });
}

/**
 * Preload Images
 */

PRELOAD_IMAGES.forEach(src => {
    const img = new Image;

    img.onload = () => {
        if (process.env.NODE_ENV === 'development') {
            console.log(`image ${src} preloaded`);
        }
    };

    img.src = src;
});
